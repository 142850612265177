export const requiredErr = "This field is required."
export const oneOfErr = "This field value should be one of the options."
export const numberTypeErr = "This field should be number."
export const atLeastOneFieldIsRequired = "At least one field is required"
export const pastTimingErr = "Start Date and time should be in future"
export const View = "view"
export const MAX_PHONE_NUMBER_LENGTH = 11
export const EXPECTED_PHONE_NUMBER_LENGTH = 10

export const STATUS_CONSTANTS = {
  Active: "Active",
  InActive: "InActive",
  Inactive: "Inactive",
  Invited: "Invited",
  Not_Installed: "Not Installed",
  In_Active: "In Active"
}
export const ROLE_CONSTANTS = {
  WisUser: "WisUser",
  Admin: "Admin",
  CorporateUser: "CorporateUser",
  RegionalUser: "RegionalUser"
}
export const RESERVATIONS_STATUS_CONSTANTS = {
  Cancelled: "Cancelled",
  Completed: "Completed",
  Confirmed: "Confirmed",
  Pending: "Pending",
  pending: "pending",
  cancelled: "cancelled",
  completed: "completed",
  confirmed: "confirmed"
}
export const ROLE_ID = {
  Admin: "1",
  CorporateUser: "2",
  RegionalUser: "3",
  WisUser: "4"
}
export const RED_COLOR_VALUE = 25
export const YELLOW_COLOR_VALUE = 75

export const MAX_DEVICES = 1000000000
export const CUSTOM_REQUEST_HEADERS = {
  "Strict-Transport-Security": "Max-age=31536000; includeSubDomains",
  "Content-Security-Policy": `script-src'self'; obje ct-src 'self'`,
  "X-Frame-Options": "Sameorigin,deny",
  "Referrer-Policy": "no-referrer",
  "X-Content-Type-Options": "No sniff"
}

export const formField = {
  text: "text",
  date: "date",
  time: "time",
  dropdown: "dropdown",
  auto_dropdown: "auto_dropdown",
  multi_dropdown: "multi_dropdown",
  multi_auto_dropdown: "multi_auto_dropdown",
  checkbox: "checkbox",
  radio: "radio",
  textarea: "textarea",
  chip: "chip"
}

export const PromptTypes = {
  Decimal: "DECIMAL",
  CustomList: "CUSTOM_LIST",
  Text: "TEXT",
  Alpha: "ALPHA",
  Numeric: "NUMERIC",
  LocationCategory: "LOCATION_CATEGORY",
  LocationRange: "LOCATION_RANGE",
  Department: "DEPARTMENT",
  ParentDepartment: "PARENT_DEPARTMENT",
  Other: "OTHER",
  NONE: "NONE",
  LEFT: "LEFT",
  RIGHT: "RIGHT",
  ZIP: "ZIP"
}

export const PromptFieldNames = {
  VariableName: "VariableName",
  CustomList: "CustomList",
  Integer: "Integer",
  DefaultValue: "DefaultValue",
  NumberOfDecimals: "NumberOfDecimals",
  Label: "Label"
}

export const CONFIG_MODULES = {
  Reports: "Reports",
  OutputFiles: "Outputs",
  LookupRoutines: "Lookup Routines",
  CountPrograms: {
    setup: "CP Setup Instructions",
    validation: "CP Validation Instructions",
    Lookup: "CP Lookup Instructions"
  },
  CountFieldInstructions: {
    setup: "CF Setup Instructions",
    validation: "CF Validation Instructions",
    Lookup: "CF Lookup Instructions",
    Location: "CF Location",
    Product: "CF Product"
  },
  countFields: "countFields",
  Audits: "Audits",
  AuditRule: "Audit Rules",
  CopyPastInstructions: {
    setup: "Setup Instructions",
    validation: "Validation Instructions",
    Lookup: "Lookup Instructions",
    LookupRoutines: "Lookup Routines Instructions"
  }
}

export const FILE_TYPE = {
  Text: "Text",
  Excel: "Excel",
  Ebcdic: "Ebcdic",
  Other: "OTHER",
  ASCIICharValue: "{ASCIIChar(x)}"
}
export const Padding_TYPE = {
  Left: "Left",
  Right: "Right"
}
export const ACEEDITOR_BACKGROUND_COLORS = {
  NormalMode: "#e6ede8",
  DisabledMode: "#f7f3f2"
}

export const EDIT_TYPE = {
  Edit: "Edit",
  Add: "Add"
}
export const API_RESPONSE = {
  SUCCESS: "200"
}

export const API_REQUESTS = {
  SQLEDITORTESTAPI: "api/ConfigurationPortal/testSql"
}

export const Accordion_Type = {
  panel1: "panel1"
}

export const CONFIRM_MODAL = {
  CONFIRM: "CONFIRM",
  CANCEL: "CANCEL",
  REMOVE: "REMOVE"
}
export const FIELD_DELIMITER = [
  "NONE",
  "TAB",
  "COMMA",
  "PIPE",
  "COLON",
  "SEMI COLON",
  "OTHER"
]
export const RECORD_DELIMITER = [
  "{CR}{LF}",
  "{LF}",
  "{CR}",
  "NONE",
  FILE_TYPE.ASCIICharValue,
  "OTHER"
]

export const Record_Delimiter_Obj = [
  {
    Name: "{CR}{LF}",
    Value: "\r\n"
  },
  {
    Name: "{LF}",
    Value: "\n"
  },
  {
    Name: "{CR}",
    Value: "\r"
  },
  {
    Name: "NONE",
    Value: "NONE"
  },
  {
    Name: FILE_TYPE.ASCIICharValue,
    Value: FILE_TYPE.ASCIICharValue
  },
  {
    Name: "OTHER",
    Value: "OTHER"
  }
]
export const FILE_TYPE_VALUES = ["TEXT", "EXCEL", "EBCDIC"]

export const UPLOAD_STATUS = {
  received: "received",
  successful: "successful",
  processing: "processing",
  failed: "failed",
  finished: "finished"
}

export const HEADER_CONSTANTS = {
  InputFile: "InputFile",
  Category: "category",
  CountField: "CountField",
  Lookup: "Lookup",
  Audit: "Audit",
  Report: "Report",
  Output: "Output",
  Variance: "variance",
  count_fields: "count-fields"
}
export const SCHEDULE_STATUS_CONSTANTS = {
  Completed: "Closed",
  InProgress: "in progress",
  Future: "Future",
  Pending: "pending",
  Confirmed: "Confirmed",
  NotStarted: "not started",
  Cancelled: "Cancelled",
  Locked: "Locked",
  NOTSTARTED: "Not Started",
  INPROGRESS: "In Progress",
  LOCK_IN_PROGRESS: "Lock In Progress",
  SOFT_CLOSE: "Soft Close"
}

export const languageCode = {
  US_EN: "US_EN",
  CA_FR: "CA_FR",
  us_en: "us_en",
  ca_fr: "ca_fr",
  es_mx: "es_mx",
  ES_MX: "ES_MX"
}
export const languageCodeMap = {
  English: "us_en",
  French: "ca_fr",
  Spanish: "es_mx"
}

export const CONFIGURATION_STATUS_CONSTANTS = {
  Active: "active",
  InACtive: "in active",
  NotInstalled: "not installed"
}
export const CONFIGURATION_CONSTANTS = {
  Deactivated: "deactivated",
  Installed: "installed"
}
export const USER_STATUS_CONSTANTS = {
  Active: "active",
  InACtive: "inactive",
  In_ACtive: "in active",
  Invited: "invited"
}
export const SECONDARY_PHONE = "secondaryPhone"
export const LIVE_EVENTS_STATUS_CONSTANTS = {
  Closed: "Closed",
  InProgress: "In Progress",
  NotStarted: "Not Started",
  Cancelled: "Cancelled"
}
export const LIVE_EVENTS_STATUS_CONSTANTS_ORDERS = [
  "not started",
  "in progress",
  "closed"
]
export const DEFAULT_DATE_FORMAT = "0001-01-01"
export const FTP_CONNECTIONS = {
  SFTP: "SFTP",
  FTP: "FTP"
}

export const SHARE_EVENTS = {
  Image_Type: "image/svg+xml",
  File_Name: "filename.svg",
  Content_Type: "content-type",
  Content_data: "multipart/form-data"
}
export const RESERVATION_FIELDS = {
  RESERVEDDEVICES: "reservedDevices",
  TRAININGDAYS: "trainingDays",
  EVENTDAYS: "eventDays",
  STARTDATE: "startDate",
  RESERVATION_NAME: "reservation"
}
export const TRAINING_DAYS: any = [1, 2, 3, 4, 5, 6, 7]
export const EVENT_DAYS: any = [0, 1, 2, 3, 4, 5, 6, 7]
export const QUERY_PARAMETERS = {
  NOFUTUREEVENTS: "NoFutureEvents",
  IDCONFIG: "IdConfig",
  EVENTIDS: "EventIds"
}
export const setIsDisableButton = (
  configTypeFromStore: any,
  x: any,
  y: any
) => {
  if (configTypeFromStore === "view") return true
  return x?.length === Object.keys(y).length
}
export const BULK_UPLOADING = {
  LOCATION_FILE_UPLOADING: "locationFileUploading",
  SCHEDULE_FILE_UPLOADING: "scheduleFIleUploading",
  USER_DEATILS_FILE_UPLOADING: "userDetailsFileUploading",
  DEVICE_RESERVATION: "deviceReservationFileUploading",
  UPLOADFILE: "uploadfile",
  DEPARTMENT_FILE_UPLOADING: "departmentFileUploading",
  VARIANCE_FILE_UPLOADING: "varianceFileUploading",
  AREA_RANGE_FILE_UPLOADING: "areaRangeFileUploading"
}
export const FEATURES = {
  COMPANY_SETTINGS: "company settings",
  USERS: "users",
  LOCATIONS: "locations",
  CONFIGURATION: "configuration",
  SCHEDULE: "schedule",
  RESERVATIONS: "reservations",
  UPLOADFILES: "upload files"
}
export const PERMISSIONS = {
  CREATE: "create",
  UPDATE: "update",
  VIEW: "view",
  DOWNLOAD: "download"
}
export const BULK_ACTIONS = {
  UPDATE: "Update",
  IGNORE: "Ignore"
}
export const KEY_CODE = {
  ENTER: "Enter",
  TAB: "Tab",
  COMMA: ",",
  BACKSPACE: "Backspace"
}
export const DESCENDING = {
  Desc: "Desc",
  Asc: "Asc"
}

export const KEY_CODES = {
  ENTER: 13,
  N1: 1,
  N2: 2,
  N3: 3,
  N4: 4,
  N5: 5,
  N6: 6,
  N7: 7,
  N8: 8,
  N9: 9,
  N10: 10,
  N11: 11,
  N12: 12,
  N13: 13,
  N14: 14,
  N15: 15,
  N16: 16,
  N200: 200
}
export const DEFAULT_RADIX = 10
export const PAGE_REFRESH_TIME = 30000
export const PAGE_REFRESH_TIME_5MINS = 300000
export const PAGE_REFRESH_TIME_1MINS = 60000
export const DATE_MILLI_SECONDS = 1000

export const THIRTYDAYS = 30
export const ONEYEAR = 1
export const SEVENDAYS = 7
export const DATEFORMATE = "DD-MM-YYYY"
export const INPUTFORMAT = "YYYY-MM-DDTHH:mm:ss.SSSZ"

export const ROLE_USER = {
  WISLABEL: "WIS User",
  WISVALUE: 4,
  WISEMAIL: "@wisintl.com",
  FLEXMAIL: "@flexcount.onmicrosoft.com",
  WISPROD: "@flexcountprod.onmicrosoft.com",
  WISSTAGE: "@flexcountstage.onmicrosoft.com",
  WISDEV: "@flexcountdev.onmicrosoft.com",
  WISQA: "@flexcountqa.onmicrosoft.com",
  WISPERF: "@flexcountperf.onmicrosoft.com"
}

export const SHIPPING_URL = {
  FEDEX_URL: "https://www.fedex.com/fedextrack/?trknbr=",
  UPS_URL: "https://www.ups.com/track?tracknum="
}

export const templateFileMap = {
  [BULK_UPLOADING.DEPARTMENT_FILE_UPLOADING]:
    "FlexCount_BulkImport_Department.csv",
  [BULK_UPLOADING.VARIANCE_FILE_UPLOADING]: "FlexCount_BulkImport_Variance.csv",
  [BULK_UPLOADING.LOCATION_FILE_UPLOADING]:
    "FlexCount_BulkImport_Locations.csv",
  [BULK_UPLOADING.USER_DEATILS_FILE_UPLOADING]:
    "FlexCount_BulkImport_Users.csv",
  [BULK_UPLOADING.SCHEDULE_FILE_UPLOADING]: "FlexCount_BulkImport_Schedule.csv",
  [BULK_UPLOADING.DEVICE_RESERVATION]: "FlexCount_BulkImport_Reservation.csv",
  [BULK_UPLOADING.AREA_RANGE_FILE_UPLOADING]: "FlexCount_Area_Range_Upload_File.csv",
}

export const disallowedExtensions = [".php", ".exe", ".java", ".ps1", ".cs"]

export const scheduleEDSecureFields = {
  eventPassword: "eventPassword",
  authorizationNumber: "authorizationNumber",
  countPIN: "countPIN",
  auditPIN: "auditPIN"
}
export const InvalidDate = "Invalid Date"

export const languages = {
  ENFLISH: "ENGLISH",
  FRENCH: "FRENCH",
  SPANISH: "SPANISH"
}
export const currencyFormatRegex = /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g

export const US_STATE_MAP = {
  al: "Alabama",
  alabama: "Alabama",
  ak: "Alaska",
  alaska: "Alaska",
  az: "Arizona",
  arizona: "Arizona",
  ar: "Arkansas",
  arkansas: "Arkansas",
  ca: "California",
  california: "California",
  co: "Colorado",
  colorado: "Colorado",
  ct: "Connecticut",
  connecticut: "Connecticut",
  de: "Delaware",
  delaware: "Delaware",
  fl: "Florida",
  florida: "Florida",
  ga: "Georgia",
  georgia: "Georgia",
  hi: "Hawaii",
  hawaii: "Hawaii",
  id: "Idaho",
  idaho: "Idaho",
  il: "Illinois",
  illinois: "Illinois",
  in: "Indiana",
  indiana: "Indiana",
  ia: "Iowa",
  iowa: "Iowa",
  ks: "Kansas",
  kansas: "Kansas",
  ky: "Kentucky",
  kentucky: "Kentucky",
  la: "Louisiana",
  louisiana: "Louisiana",
  me: "Maine",
  maine: "Maine",
  md: "Maryland",
  maryland: "Maryland",
  ma: "Massachusetts",
  massachusetts: "Massachusetts",
  mi: "Michigan",
  michigan: "Michigan",
  mn: "Minnesota",
  minnesota: "Minnesota",
  ms: "Mississippi",
  mississippi: "Mississippi",
  mo: "Missouri",
  missouri: "Missouri",
  mt: "Montana",
  montana: "Montana",
  ne: "Nebraska",
  nebraska: "Nebraska",
  nv: "Nevada",
  nevada: "Nevada",
  nh: "New Hampshire",
  "new hampshire": "New Hampshire",
  nj: "New Jersey",
  "new jersey": "New Jersey",
  nm: "New Mexico",
  "new mexico": "New Mexico",
  ny: "New York",
  "new york": "New York",
  nc: "North Carolina",
  "north carolina": "North Carolina",
  nd: "North Dakota",
  "north dakota": "North Dakota",
  oh: "Ohio",
  ohio: "Ohio",
  ok: "Oklahoma",
  oklahoma: "Oklahoma",
  or: "Oregon",
  oregon: "Oregon",
  pa: "Pennsylvania",
  pennsylvania: "Pennsylvania",
  ri: "Rhode Island",
  "rhode island": "Rhode Island",
  sc: "South Carolina",
  "south carolina": "South Carolina",
  sd: "South Dakota",
  "south dakota": "South Dakota",
  tn: "Tennessee",
  tennessee: "Tennessee",
  tx: "Texas",
  texas: "Texas",
  ut: "Utah",
  utah: "Utah",
  vt: "Vermont",
  vermont: "Vermont",
  va: "Virginia",
  virginia: "Virginia",
  wa: "Washington",
  washington: "Washington",
  wv: "West Virginia",
  "west virginia": "West Virginia",
  wi: "Wisconsin",
  wisconsin: "Wisconsin",
  wy: "Wyoming",
  wyoming: "Wyoming"
}

export const defScheduleStatus = ["In Progress", "Not Started", "Closed", "Locked", "Lock In Progress", "Soft Close"]